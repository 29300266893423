const dynamicBundlesUpdateMainImage = (
  mainImageContainer,
  listingIndex,
  image
) => {
  const imageElement = new Image();
  imageElement.src = image;
  imageElement.setAttribute(
    "class",
    "max-w-full max-h-screen absolute opacity-0"
  );

  imageElement.setAttribute("id", "dynamic-bundle-main-image");
  imageElement.setAttribute("data-dynamic-bundle-target", "mainImage");
  imageElement.setAttribute("data-current-listing-index", listingIndex);

  imageElement.setAttribute("data-current-listing-index", listingIndex);

  mainImageContainer.appendChild(imageElement);

  imageElement.onload = () => {
    imageElement.classList.remove("opacity-0");
    const imageContainer = document.getElementById(
      "dynamic-bundle-main-image-container"
    );

    [...imageContainer.children].forEach(img => {
      if (img !== imageElement) {
        img.remove();
      }
    });
  };
};

const dynamicBundlesClearBorder = () => {
  const mainImage = document.getElementById("dynamic-bundle-main-image");

  const listingIndexToRemove = mainImage.dataset.currentListingIndex;

  const listingElement = document.getElementById(
    `related-listings-listing-${listingIndexToRemove}`
  );

  if (listingElement) {
    const labelElement = listingElement.querySelector(".product-image");

    labelElement.classList.remove("border-ink");
    labelElement.classList.add("border-transparent");
  }
};

const dynamicBundlesAddBorderToCurrentIndex = event => {
  const imageLabel = event.target.closest("label");

  imageLabel.classList.add("border-ink");
  imageLabel.classList.remove("border-transparent");
};

// eslint-disable-next-line import/prefer-default-export
export {
  dynamicBundlesUpdateMainImage,
  dynamicBundlesClearBorder,
  dynamicBundlesAddBorderToCurrentIndex
};
