import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";
import { dynamicBundleReplaceListingsPath } from "../../routes";
import {
  dynamicBundlesAddBorderToCurrentIndex,
  dynamicBundlesClearBorder,
  dynamicBundlesUpdateMainImage
} from "../../utils/dynamicBundles";

export default class extends Controller {
  static targets = ["mainImage", "form", "mainImageContainer"];

  static values = {
    partIds: String,
    index: Number,
    bundleSlug: String,
    image: String
  };

  connect() {
    enter(this.element);
  }

  handleOptionChange(event) {
    this.updateFormAction(event);
    dynamicBundlesClearBorder();
    dynamicBundlesAddBorderToCurrentIndex(event);
    this.updateMainImage(event);
  }

  updateMainImage(event) {
    const mainImageContainer = this.mainImageContainerTarget;
    const { listingIndex } = event.target.dataset;
    const { image } = event.target.dataset;

    dynamicBundlesUpdateMainImage(mainImageContainer, listingIndex, image);
  }

  updateFormAction(event) {
    const partIdsArray = this.partIdsValue.split("/");
    const newListingId = event.target.dataset.listingId;
    partIdsArray[this.indexValue] = newListingId;

    this.formTarget.action = dynamicBundleReplaceListingsPath({
      dynamic_bundle_slug: this.bundleSlugValue,
      bundleable_ids: partIdsArray.join("/")
    });
  }

  closeModal() {
    leave(this.element);
  }
}
