import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["image"];

  static values = {
    listingId: Number,
    currentListing: Number,
    listingIndex: Number
  };

  connect() {
    if (this.currentListingValue === this.listingIdValue) {
      this.addBorderToCurrentIndex();
      this.addCurrentListingIndexToMainImage();
    }
  }

  addBorderToCurrentIndex() {
    this.imageTarget.classList.add("border-ink");
    this.imageTarget.classList.remove("border-transparent");
  }

  addCurrentListingIndexToMainImage() {
    const mainImage = document.getElementById("dynamic-bundle-main-image");
    mainImage.setAttribute(
      "data-current-listing-index",
      this.listingIndexValue
    );
  }
}
